<template>
  <div class="bg-white min-h-screen">
    <div class="w-full max-w-7xl mx-auto p-4">
      <div class="flex justify-between items-center mb-4">
        <div class="relative flex items-center w-full max-w-md">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="search with community did"
            class="w-full pl-10 pr-24 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm transition duration-150 ease-in-out"
          />
          <span class="absolute left-3 top-1/2 transform -translate-y-1/2">
            <i class="fas fa-search text-gray-400"></i>
          </span>
          <button
            @click="handleSearch"
            class="absolute right-1 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-indigo-600 text-white rounded-md"
          >
            Search
          </button>
        </div>
        <div class="flex items-center gap-4 bg-white p-4 rounded-md shadow-sm">
          <p class="text-sm text-gray-400">
            communities count : <span>{{ store.currentTotalItems }}</span>
          </p>
          <button
            @click="toggleShowHideCommunities"
            class="bg-indigo-600 text-white px-4 py-2 rounded-md"
          >
            {{ store.isHiddenCommunities ? 'Shown' : 'Hidden' }}
            <span v-if="!store.isHiddenCommunities">
              <i class="fa fa-eye-slash"></i>
            </span>
            <span v-if="store.isHiddenCommunities">
              <i class="fa fa-light fa-eye"></i>
            </span>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 my-10 md:grid-cols-3 gap-7">
        <div
          v-for="(community, index) in store.currentCommunities"
          :key="index"
          class="relative border rounded-lg overflow-hidden shadow-sm"
          :class="store.isHiddenCommunities ? 'opacity-50' : ''"
        >
          <router-link :to="'/admin/community/' + community.did">
            <img
              :src="community.templateData.image"
              :alt="community.templateData.name"
              class="w-full h-36 object-cover"
            />
            <div class="p-5">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2">
                  <img
                    :src="community.issuerProfile.profileImage"
                    alt="Avatar"
                    class="w-8 h-8 rounded-full"
                  />
                  <span class="font-medium text-[18px]">{{
                    community.issuerProfile.name
                  }}</span>
                </div>
                <span class="text-sm text-black text-[22px]">{{
                  community.createdDate.slice(-4)
                }}</span>
              </div>
              <div class="flex items-center justify-between mt-1">
                <span class="text-[18px] font-semibold ml-10">{{
                  community.issuerProfile.sector
                }}</span>
              </div>
            </div>
            <p
              class="absolute top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-1 rounded-full text-sm"
            >
              <img
                :src="require('/assets/images/Certif.png')"
                alt="Avatar"
                class="w-6 h-6 rounded-full"
              />
            </p>
          </router-link>
          <div
            class="absolute bottom-0 right-0 flex items-center gap-2 p-3 bg-white"
          >
            <button
              @click="hideCommunity(community.did)"
              class="bg-indigo-600 text-white px-2 py-1 rounded-md"
            >
              <span v-if="!store.isHiddenCommunities">
                <i class="fa fa-eye-slash"></i>
              </span>
              <span v-if="store.isHiddenCommunities">
                <i class="fa fa-light fa-eye"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="!store.initialLoading" class="flex justify-center mt-6">
        <button
          v-if="
            store.currentStart < store.currentTotalItems && !store.loadingMore
          "
          @click="loadMore"
          class="bg-indigo-600 text-white px-4 py-2 rounded-md"
        >
          Load more
        </button>
        <div v-if="store.loadingMore" class="flex items-center gap-3">
          <custom-loader class="" color="#D0C4E7" size="30px"></custom-loader>
          <p class="text-lg text-gray-400">Loading more...</p>
        </div>
      </div>
    </div>
    <div
      v-if="store.initialLoading"
      class="flex justify-center items-center gap-3 mt-10"
    >
      <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      <p class="text-lg text-gray-400">Loading...</p>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useCommunityStore } from '../stores/community';

const api = inject('api');
const utils = inject('utils');
const $cookies = inject('$cookies');
const router = useRouter();
const store = useCommunityStore();

const searchQuery = ref('');
const limit = ref(15);

const verifyToken = async () => {
  const jwToken = $cookies.get('token');
  if (jwToken) {
    try {
      await api.verifyJwt(jwToken);
    } catch (error) {
      console.error('Error verifying token:', error);
      router.push('/admin-login');
    }
  } else {
    router.push('/admin-login');
  }
};

const fetchCommunityDetails = async (address) => {
  try {
    const templates = await utils.getTemplateList(address, 'Membership');
    if (templates && templates.length > 0) {
      const templateHash = templates[0].templateHash;
      const oldestTemplateHash = await utils.getOldestTemplateHash(
        templateHash
      );
      const templateData = await utils.getTemplateData(oldestTemplateHash);
      const profile = await api.getIssuerDetailsLinkFromCode(
        templateData.issuerDID
      );

      const profileIpfsUrl = profile.data.service[0]?.serviceEndpoint;
      const issuerProfile = await utils.getJsonDataFromUrl(profileIpfsUrl);

      return {
        did: templateData.issuerDID,
        templateData: {
          name: templateData.name,
          image: templateData.image,
        },
        issuerProfile: {
          name: issuerProfile.name,
          profileImage: issuerProfile.profileImage,
          sector: issuerProfile.sector,
        },
        createdDate: new Date(templateData.created).toLocaleDateString(),
      };
    }
  } catch (error) {
    console.error('Error fetching community details:', error);
  }
};

const fetchCommunities = async (isInitial = false) => {
  try {
    if (isInitial) {
      store.setInitialLoading(true);
    } else {
      store.setLoadingMore(true);
    }

    const response = await api.getPublicCommunities(
      store.currentStart,
      limit.value,
      store.isHiddenCommunities
    );
    const communitiesAddresses = response.data.data;

    store.setStart(store.currentStart + limit.value);
    store.setTotalItems(response.data.pagination.totalItems);

    const communityPromises = communitiesAddresses.map((data) =>
      fetchCommunityDetails(data.address)
    );
    const fetchedCommunities = await Promise.all(communityPromises);
    const validCommunities = fetchedCommunities.filter(Boolean);

    store.addCommunities(validCommunities);
  } catch (error) {
    console.error('Error fetching public communities:', error);
  } finally {
    store.setInitialLoading(false);
    store.setLoadingMore(false);
  }
};

const loadMore = () => {
  if (store.currentStart < store.currentTotalItems && !store.loadingMore) {
    fetchCommunities(false);
  }
};

const handleSearch = () => {
  if (searchQuery.value.length > 0 && searchQuery.value.startsWith('did:')) {
    router.push(`/admin/community/${searchQuery.value.trim()}`);
  }
};

const hideCommunity = async (did) => {
  if (window.confirm('Are you sure you want to hide this community?')) {
    try {
      store.setInitialLoading(true);
      await api.hideCommunityAddress(utils.didToAddress(did));
      store.removeCommunity(did);
    } catch (error) {
      console.error('Error hiding community:', error);
    } finally {
      store.setInitialLoading(false);
    }
  }
};

const toggleShowHideCommunities = () => {
  store.setIsHiddenCommunities(!store.isHiddenCommunities);
  if (store.currentCommunities.length === 0) {
    fetchCommunities(true);
  }
};

onMounted(() => {
  verifyToken();
  if (store.currentCommunities.length === 0) {
    fetchCommunities(true);
  }
});
</script>
