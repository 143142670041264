<template>
  <div class="relative w-full mx-auto p-4 lg:p-8">
    <div
      v-if="loading >= 3 && !errorMessage"
      class="w-full 2xl:max-w-[80%] mx-auto p-4 lg:p-8 rounded-lg shadow border-gray-100 border-[0.2px] mb-16"
    >
      <div
        class="flex flex-col justify-center items-center lg:flex-row lg:items-start bg-white p-6 lg:p-10 rounded-lg mt-8"
      >
        <div
          class="lg:basis-3/4 basis-1/2 flex-col lg:flex-row flex items-center lg:items-start space-x-0 md:space-x-4"
        >
          <div
            class="flex items-center justify-center rounded-full w-24 h-24 lg:w-36 lg:h-36"
          >
            <img
              :src="
                profile?.profileImage ||
                require('/assets/images/logo_comect.png')
              "
              alt="Logo"
              class="w-20 h-20 lg:w-[110px] lg:h-[110px] rounded-full"
            />
          </div>
          <div class="flex flex-col w-full">
            <div
              class="space-y-1 flex flex-col justify-center items-center lg:justify-start lg:items-start"
            >
              <div
                class="flex items-center justify-center lg:justify-start space-x-2 font-semibold text-[#050931]"
              >
                <p v-if="profile?.name" class="text-xl lg:text-2xl">
                  {{ profile?.name }}
                </p>
                <img
                  :src="require('/assets/images/Certif.png')"
                  alt="Certif"
                  class="h-5 w-5 lg:h-6 lg:w-6"
                />
              </div>
              <p class="text-base lg:text-lg font-sans text-[#050931]">
                {{ profile?.sector }}
              </p>
            </div>

            <div
              v-if="invitationLink"
              class="flex flex-col lg:flex-row justify-between items-center py-5 pl-0 lg:pl-54"
            >
              <div>
                <button
                  @click="showDynamicQrCode = true"
                  class="bg-[#4030C6] flex justify-center items-center w-28 h-8 text-white font-semibold rounded-lg"
                >
                  Join
                </button>
              </div>
              <div
                v-if="
                  templateLists &&
                  templateLists['Membership'] &&
                  templateLists['Membership'][0] &&
                  memberCount
                "
                class="flex space-x-4 mt-5 lg:mt-0"
              >
                <div class="flex items-center space-x-2">
                  <img
                    :src="require('/assets/images/user.png')"
                    alt="Members"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]"
                    >{{ memberCount }} Members</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <img
                    :src="require('/assets/images/CertifLight.png')"
                    alt="Badges"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]"
                    >{{
                      Object.keys(templateLists).reduce((cnt, type) => {
                        return cnt + templateLists[type].length;
                      }, 0)
                    }}
                    Badges</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <img
                    :src="require('/assets/images/calendar_.png')"
                    alt="Date"
                    class="h-[19.29px] w-[19.29px]"
                  />
                  <span class="text-black font-medium text-[14px]">
                    {{ createdDate || 'N/A' }}
                  </span>
                </div>
              </div>
            </div>

            <div class="py-5">
              <p
                class="text-[17px] font-semibold text-start w-full text-gray-900"
              >
                Description
              </p>
              <p class="text-black text-[17px]" style="font-weight: 400">
                {{ profile?.description }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="
            templateLists &&
            templateLists['Membership'] &&
            templateLists['Membership'][0] &&
            templateData
          "
          class="lg:basis-1.5/4 basis-1/2 mt-5 lg:mt-0 px-4"
        >
          <img
            :src="
              templateData['Membership'][0]?.data?.image ||
              require('/assets/images/banner.png')
            "
            alt="Banner"
            class="w-[100%] md:w-[80%] rounded-lg mx-auto"
          />
        </div>
      </div>
      <div v-if="socialMedia.length > 0" class="w-full px-0 lg:px-44">
        <p class="text-2xl my-5 font-semibold text-start w-full text-gray-900">
          Social
        </p>
        <div class="flex space-x-4 w-full mx-auto">
          <template v-for="social in socialMedia" :key="index">
            <a
              v-if="social.value"
              :href="social.value"
              target="_blank"
              class="flex items-center justify-center space-x-1 border-gray-300 border-[1px] w-24 h-7 rounded-full"
            >
              <icon
                :name="social.name"
                class="fill-black w-4 h-4"
                :class="{
                  'fill-indigo-700': social.name == 'website',
                  '!fill-[#7289da]': social.name == 'discord',
                }"
              />
              <p class="text-start text-sm text-gray-400">
                {{ social.name[0].toUpperCase() + social.name.slice(1) }}
              </p>
            </a>
          </template>
        </div>
      </div>
      <div class="w-full border-t lg:px-10 border-[#E5E7EB] my-3"></div>
      <div class="" v-if="templateData && Object.keys(templateData).length > 0">
        <div
          v-for="(templates, type) in templateData"
          :key="type"
          class="flex justify-center w-full items-center flex-col"
        >
          <h2
            v-if="templates.length > 0 && type !== 'Membership'"
            class="text-2xl my-10 lg:px-44 font-semibold text-start w-full text-gray-900"
          >
            {{
              type === 'Basic'
                ? 'Certified'
                : type === 'Community'
                ? 'Community'
                : type === 'Participation'
                ? 'Participation'
                : type === 'Role'
                ? 'Role'
                : type
            }}
            badges
          </h2>

          <div
            v-if="templates.length > 0 && profile && type !== 'Membership'"
            class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 lg:grid-cols-4 py-3 2xl:gap-20  px-0 2xl:grid-cols-5 gap-5"
          >
            <div
              v-for="(template, index) in visibilityState[type]
                ? templates
                : templates.slice(0, 5)"
              :key="index"
              class="rounded-lg relative justify-center items-center flex flex-col "
            >
              <!--  Badge Preview opacity if badge is revoked -->
              <div
                @click.stop="openSidebar(template)"
                :class="
                  (template.status && template.status.value == 1) ||
                  loadingToggleRevoke
                    ? 'opacity-50'
                    : ''
                "
                class="rounded-lg relative"
              >
                <TemplatePreview
                  :badgeImage="template.data.image"
                  :badgeName="template.data.name"
                  :badgeLocation="null"
                  :badgeStartDate="null"
                  :badgeEndDate="null"
                  :issuerName="profile.name"
                  :formatMd="true"
                  :type="type.toLowerCase()"
                  :isCommunityPage="true"
                  :profileImage="profile.profileImage"
                />
              </div>
              <small class="pb-2">
                Updating on :
                {{
                  new Date(
                    parseInt(template.status.timestamp) * 1000
                  ).toLocaleDateString()
                }}
              </small>
              <div class="flex justify-center w-5xl items-center">
                <button
                  :disabled="loadingToggleRevoke"
                  v-if="template.status && template.status.value == 0"
                  @click="toggleRevoke(template, true)"
                  :class="loadingToggleRevoke ? 'bg-gray-300' : 'bg-red-500'"
                  class="flex justify-center items-center w-28 h-8 mx-1 2xl:text-lg 2xl:w-36 2xl:h-10 text-white font-semibold rounded-lg"
                >
                  <span v-if="loadingToggleRevoke">
                    <custom-loader
                      v-if="loadingToggleRevoke"
                      class="m-2"
                      color="#fff"
                      size="20px"
                    ></custom-loader>
                  </span>
                  {{ loadingToggleRevoke ? 'wait...' : 'Revoke' }}
                </button>
                <button
                  :disabled="loadingToggleRevoke"
                  v-if="template.status && template.status.value == 1"
                  @click="toggleRevoke(template, false)"
                  :class="loadingToggleRevoke ? 'bg-gray-300' : 'bg-secondary'"
                  class="flex justify-center items-center w-28 h-8 mx-1 2xl:text-lg 2xl:w-36 2xl:h-10 text-white font-semibold rounded-lg"
                >
                  <span v-if="loadingToggleRevoke">
                    <custom-loader
                      v-if="loadingToggleRevoke"
                      class="m-2"
                      color="#fff"
                      size="20px"
                    ></custom-loader>
                  </span>
                  {{ loadingToggleRevoke ? 'wait...' : 'Reactivate' }}
                </button>
              </div>
            </div>
          </div>

          <!-- New Sidebar Component -->
          <transition name="slide-fade">
            <div
              v-show="showSidebar"
              class="fixed inset-y-0 right-0 w-[500px] bg-white shadow-lg z-50 overflow-y-auto"
            >
              <div class="p-6 space-y-6">
                <div class="flex justify-between items-center">
                  <h2 class="text-2xl font-bold text-gray-800">
                    Badge Details
                  </h2>
                  <button
                    @click="closeSidebar"
                    class="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div v-if="selectedTemplate" class="space-y-6">
                  <img
                    :src="selectedTemplate.data.image"
                    :alt="selectedTemplate.data.name"
                    class="w-full rounded-lg shadow-md"
                  />

                  <div class="bg-gray-50 p-4 rounded-lg">
                    <h3 class="text-xl font-semibold text-gray-800 mb-2">
                      {{ selectedTemplate.data.name }}
                    </h3>
                    <div class="flex items-center space-x-2 text-sm">
                      <span
                        class="px-2 py-1 rounded-full text-xs font-medium"
                        :class="
                          selectedTemplate.status.value == 0
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        "
                      >
                        {{
                          selectedTemplate.status.value == 0
                            ? 'Active'
                            : 'Revoked'
                        }}
                      </span>
                      <span class="text-gray-500">
                        <icon name="event_date" class="fill-black w-[20px]" />
                        {{
                          new Date(
                            parseInt(selectedTemplate.status.timestamp) * 1000
                          ).toLocaleDateString()
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="space-y-4">
                    <div class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-500 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                      <span class="text-gray-700 font-medium">Category:</span>
                      <span class="ml-2 text-gray-600">{{
                        selectedTemplate.data.badgeCategory === 'Basic'
                          ? 'Certified'
                          : selectedTemplate.data.badgeCategory === 'Community'
                          ? 'Community'
                          : selectedTemplate.data.badgeCategory ===
                            'Participation'
                          ? 'Participation'
                          : selectedTemplate.data.badgeCategory === 'Role'
                          ? 'Role'
                          : selectedTemplate.data.badgeCategory
                      }}</span>
                    </div>

                    <div class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-500 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                      <span class="text-gray-700 font-medium">Issuer:</span>
                      <span class="ml-2 text-gray-600 truncate">{{
                        selectedTemplate.data.issuerDID.slice(0, 30) + '...'
                      }}</span>
                      <button @click.stop="copyCode" class="cursor-pointer">
                        <icon name="copy_1" class="fill-black w-[20px]" />
                      </button>
                    </div>

                    <div>
                      <div class="flex items-center my-2">
                        <icon name="description" class="fill-black w-[20px]" />
                        <span class="text-gray-700 px-2 font-medium"
                          >Description:</span
                        >
                      </div>
                      <p class="text-gray-600 ml-7">
                        {{ selectedTemplate.data.description }}
                      </p>
                    </div>

                    <div>
                      <div class="flex items-center mb-2">
                        <icon name="criteria" class="fill-black w-[20px]" />
                        <span class="text-gray-700 px-2 font-medium"
                          >Criteria:</span
                        >
                      </div>
                      <p class="text-gray-600 ml-7">
                        {{ selectedTemplate.data.criteria }}
                      </p>
                    </div>

                    <div class="flex items-center">
                      <icon name="event_date" class="fill-black w-[20px]" />
                      <span class="text-gray-700 px-2 font-medium"
                        >Created:</span
                      >
                      <span class="ml-2 text-gray-600">{{
                        formatDate(selectedTemplate.data.created)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="flex justify-end w-5xl items-center">
            <button
              v-if="templateData && templateData[type].length > 5"
              @click="toggleShowAll(type)"
              class="flex justify-end items-end w-28 h-8 2xl:text-lg 2xl:w-36 2xl:h-10 text-black font-semibold rounded-lg"
            >
              {{ visibilityState[type] ? 'Show less' : 'Show all' }}
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="!templateData || Object.keys(templateData).length === 0"
        class="flex justify-center w-full items-center flex-col"
      >
        <p
          class="text-2xl my-10 lg:px-44 font-semibold text-center w-full text-gray-900"
        >
          No badges yet
        </p>
      </div>

      <div
        v-if="invitationLink"
        class="w-full max-w-lg mx-auto p-4 lg:px-8 space-y-5 2xl:text-xl flex flex-col items-center"
      >
        <p class="text-center font-medium text-black">
          To discover and explore badges, join the community download and use
          the myDID APP
        </p>
        <button
          @click="showDynamicQrCode = true"
          class="bg-[#4030C6] flex justify-center items-center w-28 h-8 2xl:text-xl 2xl:w-36 2xl:h-10 text-white font-semibold rounded-lg"
        >
          Discover
        </button>
      </div>
    </div>
    <div
      v-else-if="loading < 3 && !errorMessage"
      class="flex justify-center items-center"
    >
      <div class="flex justify-center mb-5">
        <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      </div>
    </div>
    <div v-else-if="errorMessage">
      <p>{{ errorMessage }}</p>
    </div>

    <div v-if="showDynamicQrCode">
      <div
        @click="showDynamicQrCode = false"
        class="w-full h-full fixed top-0 left-0 z-40 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <div
          class="overflow-y-auto overflow-x-hidden rounded-lg shadow-lg bg-white p-8"
        >
          <h2 class="text-xl font-bold mb-4">
            Scan this QR code to join the community
          </h2>
          <div class="bg-white flex justify-center mb-10">
            <qrcode-vue
              :value="invitationLink"
              :size="250"
              level="L"
              :margin="1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, inject } from 'vue';
import TheFooter from '@/components/TheFooter.vue';
import TemplatePreview from '@/components/TemplatePreview.vue';
import TheHeader from '@/components/TheHeader.vue';
import { createToast } from 'mosha-vue-toastify';
export default {
  components: { TheHeader, TemplatePreview, TheFooter },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const api = inject('api');
    const utils = inject('utils');
    const $cookies = inject('$cookies');

    const profile = ref(null);
    const socialMedia = ref([]);
    const selectedTemplate = ref(null);
    const lang = ref('en');
    const loading = ref(false);
    const loadingToggleRevoke = ref(false);
    const errorMessage = ref(null);
    const memberCount = ref(0);
    const showDynamicQrCode = ref(false);
    const invitationLink = ref('');
    const memberShipTemplateHash = ref(null);
    const createdDate = ref(null);
    const templateData = ref(null);
    const visibilityState = ref({
      Basic: false,
      Community: false,
      Participation: false,
      Role: false,
    });
    const templateLists = ref(null);
    const showSidebar = ref(false);

    const did = route.params.id;

    const verifyToken = async () => {
      const jwToken = $cookies.get('token');
      if (jwToken) {
        try {
          await api.verifyJwt(jwToken);
        } catch (error) {
          router.push('/admin-login');
        }

        return;
      } else {
        return router.push('/admin-login');
      }
    };
    verifyToken();

    const addr = utils.didToAddress(did);
    const fetchData = async () => {
      if (did) {
        loading.value = 0;
        api.getIssuerDetailsLinkFromCode(did).then((res) => {
          const profileIpfsUrl = res.data.service[0]?.serviceEndpoint;
          utils.getJsonDataFromUrl(profileIpfsUrl).then((issuerProfile) => {
            profile.value = issuerProfile;
            const socialNetworks = ['twitter', 'discord', 'website'];
            socialMedia.value = socialNetworks
              .filter((network) => issuerProfile[network])
              .map((network) => {
                return {
                  name: network,
                  value: issuerProfile[network],
                };
              });
          });
        });

        Promise.all(
          ['Basic', 'Community', 'Participation', 'Membership', 'Role'].map(
            (type) => {
              return new Promise(async (resolve, reject) => {
                const templateList = await utils.getTemplateList(addr, type);

                resolve({ type, list: templateList });
              });
            }
          )
        )
          .then((res) => {
            const lists = {};
            res.forEach((el) => (lists[el.type] = el.list));
            templateLists.value = lists;
            memberShipTemplateHash.value = lists['Membership'][0]?.templateHash;

            const templateTypes = [
              'Basic',
              'Community',
              'Participation',
              'Role',
              'Membership',
            ];

            const templatePromises = templateTypes.map(async (type) => {
              const templateListData = await utils.getTemplateListData(
                lists[type]
              );

              // Add custom fields and status from the first template (index 0) to each template
              const data = templateListData.map((template) => ({
                ...template,
                status: lists[type].find(
                  (t) => t.templateHash === template.templateHash
                )?.status,
              }));

              return [type, data];
            });

            Promise.all(templatePromises).then((results) => {
              templateData.value = Object.fromEntries(results);
              loading.value = loading.value + 1;
            });

            api
              .getCommunityMemberCount(
                memberShipTemplateHash.value.split('0x')[1]
              )
              .then((res) => {
                memberCount.value = res.data.members.number;
                loading.value = loading.value + 1;
              });

            // Get the older template hash from the membership template history
            utils
              .getOldestTemplateHash(memberShipTemplateHash.value)
              .then((hash) => {
                utils.getTemplateData(hash).then((templateData) => {
                  createdDate.value = new Date(
                    templateData.created
                  ).toLocaleDateString();
                  loading.value = loading.value + 1;
                });
              });
          })
          .catch((error) => {
            errorMessage.value = 'Error fetching template lists';
            loading.value = 10;
          });

        // Get invitation link from the issuer card
        utils.getMemberCardsList().then((memberCardList) => {
          const memberCard = memberCardList.find(
            (card) => card.issuer === addr
          );
          if (!memberCard) return;

          const invitationLinkCode = utils.removeLeadingZerosAndConvertToASCII(
            memberCard.invitationLink
          );

          invitationLink.value = `${process.env.VUE_APP_REDIRECT_LINK}/${invitationLinkCode}`;
          loading.value = loading.value + 1;
        });
      }
    };

    fetchData();

    const toggleShowAll = (type) => {
      visibilityState.value[type] = !visibilityState.value[type];
    };

    const toggleRevoke = async (template, isRevokation) => {
      loadingToggleRevoke.value = true;
      const categoryTypes = [
        'Basic',
        'Community',
        'Participation',
        'Membership',
        'Role',
      ];
      const templateCategory = categoryTypes.findIndex(
        (type) => type === template.data.badgeCategory
      );

      try {
        await utils.toggleBadgeStatus(
          utils.didToAddress(template.data.issuerDID),
          template.index,
          templateCategory,
          isRevokation
        );
      } catch (error) {
        loadingToggleRevoke.value = false;
        return;
      }

      // set interval to check if the transaction is mined
      const interval = setInterval(async () => {
        try {
          const updatedTemplate = await utils.getTemplateStatus(
            utils.didToAddress(template.data.issuerDID),
            templateCategory,
            template.index
          );
          if (
            isRevokation
              ? updatedTemplate['status'].value == '1'
              : updatedTemplate['status'].value == '0'
          ) {
            clearInterval(interval);
            templateData.value[template.data.badgeCategory] =
              templateData.value[template.data.badgeCategory].map(
                (template) => {
                  if (template.templateHash === updatedTemplate.templateHash) {
                    return {
                      ...template,
                      status: updatedTemplate['status'],
                    };
                  }
                  return template;
                }
              );

            loadingToggleRevoke.value = false;
          }
        } catch (error) {
          console.error('Error checking template status:', error);
          clearInterval(interval);
          loadingToggleRevoke.value = false;
        }
      }, 1000);
    };

    const openSidebar = (template) => {
      console.log('template', template);
      selectedTemplate.value = template;
      showSidebar.value = true;
    };

    const closeSidebar = () => {
      showSidebar.value = false;
      selectedTemplate.value = null;
    };

    const copyCode = () => {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(
          selectedTemplate.value.data.issuerDID
        ).then(
          () => {
            createToast('Code copied to clipboard', {
              type: 'success',
              showIcon: true,
              position: 'top-right',
              toastBackgroundColor: '#050931',
            });
            console.log('Code copied to clipboard');
          },
          (err) => {
            console.error('Could not copy text: ', err);
          }
        );
      }
    };
    const formatDate = (dateString) => {
      return utils.formatDateWithRelativeTime(dateString);
    };

    return {
      profile,
      lang,
      loading,
      did,
      templateLists,
      memberCount,
      createdDate,
      errorMessage,
      invitationLink,
      showDynamicQrCode,
      socialMedia,
      templateData,
      visibilityState,
      toggleShowAll,
      toggleRevoke,
      loadingToggleRevoke,
      openSidebar,
      closeSidebar,
      selectedTemplate,
      showSidebar,
      copyCode,
      formatDate,
    };
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
