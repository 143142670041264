<template>
  <main class="flex flex-col w-full">
    <div class="flex flex-col">
      <div class="flex-grow">
        <div
          v-if="!vpDetails && !loading"
          class="px-4 py-9 flex justify-center items-start text-3xl h-screen"
        >
          <p class="text-3xl">Badge not found</p>
        </div>
        <div v-else-if="!vpDetails && loading" class="px-4">
          <div class="flex justify-center mb-5">
            <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
          </div>
          <p class="text-secondary text-center mb-3">Checking information</p>
        </div>
        <div v-else-if="vpDetails && vpDetails.verifiableCredentials" class="">
          <div class="flex flex-col items-center py-9 px-5">
            <div
              v-for="vc in vpDetails.verifiableCredentials"
              class="w-full 2xl:max-w-[70%] mx-auto p-4 lg:p-8 rounded-lg shadow border-gray-100 border-[0.2px] mb-16"
            >
              <vc-presentation
                :vc="{
                  ...vc,
                  proof_date: vpDetails.verifiablePresentation.created,
                }"
                :lang="lang"
              ></vc-presentation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
export default {
  setup() {
    const route = useRoute();
    const api = inject('api');

    const vpDetails = ref(null);
    const lang = ref('en');
    const loading = ref(false);

    try {
      if (route.query.code) {
        loading.value = true;
        api
          .getVPDetailsFromCode(route.query.code)
          .then((response) => {
            loading.value = false;
            console.log(response.data);
            vpDetails.value = response.data;
          })
          .catch((e) => {
            loading.value = false;
            console.log(e);
          });
      } else {
        router.push('/welcome');
      }

      if (route.query.lang) lang.value = route.query.lang;
    } catch (e) {
      console.log(e);
    }

    return { vpDetails, lang, loading };
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.flex-grow {
  flex-grow: 1;
}

footer {
  @apply flex flex-col md:flex-row items-center space-y-2 md:space-y-0 space-x-0 md:space-x-2 py-8 justify-center border-t;
}

.footer a {
  @apply text-primary   hover:underline;
}

.footer span {
  @apply text-gray-400;
}
</style>
